<template>
  <div>
    <b-card>
      <b-row class="mb-2 px-2">
        <b-button
          variant="primary"
          @click="add"
        >
          {{ $t('general.add') }}
        </b-button>
      </b-row>
      <DraggableTable
        :list="GET_FORM_TYPES.results"
        @change="onChangePosition"
        @edit="edit"
        @delete="deleteItem"
      />
      <!-- <b-table
        hover
        responsive
        show-empty
        :fields="fields"
        :items="GET_FORM_TYPES.results"
        style-class="slots_text_align vgt-table bordered"
      >
        <template
          #empty
        >
          <div class="text-center text-secondary">
            {{ $t('general.no_data_to_view') }}
          </div>
        </template>
        <template #head()="{ label}">
          {{ $t(label) }}
        </template>
        <template #cell(subject)="{value}">
          {{ value.title }}
        </template>
        <template
          #cell(actions)="data"
        >
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="edit(data.item)"
            >
              <feather-icon
                size="22"
                icon="EditIcon"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="deleteItem(data.item.id)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </template>

      </b-table> -->

      <!-- pagination -->
      <!-- slot-scope="props" -->
      <template>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">{{ $t('general.show_from') }}</span>
            <b-form-select
              v-model="params.page_size"
              :options="pageOptions"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
            <!-- из {{ props.total }} -->
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="GET_FORM_TYPES.count"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </b-card>
    <AddEditFormTypeModal :isEdit="!!item_to_edit.id" @success="updateList" :editData="item_to_edit" />
  </div>
</template>

<script>
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  BCard,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import toast from '@/mixins/toast'
import AddEditFormTypeModal from './components/AddEditFormTypeModal.vue'
import DraggableTable from './components/DraggableTable.vue'

export default {
  components: {
    BCard,
    BTable,
    BFormSelect,
    BPagination,
    BButton,
    BRow,
    AddEditFormTypeModal,
    DraggableTable,
  },
  directives: {
    Ripple,
  },

  mixins: [areYouSure, toast],
  data() {
    return {
      pageOptions: [3, 5, 10],
      params: {
        page_size: 1000,
        page: 1,
      },
      item_to_edit: {},
      fields: [
        {
          label: 'general.title',
          key: 'title',
        },
        {
          label: 'general.actions',
          key: 'actions',
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_FORM_TYPES']),
  },
  mounted() {
    this.FETCH_FORM_TYPES(this.params)
  },
  methods: {
    ...mapActions('courses', ['FETCH_FORM_TYPES', 'DELETE_FORM_TYPE', 'CHANGE_POSITION_FORM_TYPE']),
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_FORM_TYPES(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_FORM_TYPES(this.params)
    },
    updateList() {
      this.params.page = 1
      this.FETCH_FORM_TYPES(this.params)
    },
    edit(data) {
      this.item_to_edit = data
      this.$nextTick(() => {
        this.$bvModal.show('AddEditFormTypeModal')
      })
    },
    add() {
      this.item_to_edit = {}
      this.$nextTick(() => {
        this.$bvModal.show('AddEditFormTypeModal')
      })
    },
    deleteItem(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_FORM_TYPE(id)
            .then(() => {
              this.$_successToast(this.$t('general.removed'))
              this.params.page = 1
              this.updateList()
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    onChangePosition(e) {
      console.log(e)
      const body = {
        id: e.moved.element.id,
        from: e.moved.oldIndex,
        to: e.moved.newIndex,
      }
      console.log(body)
      this.CHANGE_POSITION_FORM_TYPE(body).then(() => {
        this.updateList()
      })
    },
  },
}
</script>

<style>

</style>
