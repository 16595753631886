<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <template v-for="i in fields">
          <th
            :key="i.key"
            scope="col"
            :class="i.thClass"
          >
            {{ $t(i.label) }}
          </th>
        </template>
      </tr>
    </thead>
    <draggable
      v-model="list"
      :disabled="$can('manage', 'admin')"
      tag="tbody"
      @change="$emit('change', $event)"
    >
      <tr
        v-for="item in list"
        :key="`tablle${item.id}`"
      >
        <td scope="row">
          {{ item.title }}
        </td>
        <td>
          <div
            class="d-flex align-items-center justify-content-center no-wrap"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$emit('edit', item)"
            >
              <feather-icon
                icon="EditIcon"
                size="22"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="$emit('delete', item.id)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </td>
      </tr>
    </draggable>
  </table>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    draggable,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      fields: [
        {
          label: 'title',
          key: 'title',
        },
        {
          label: 'general.actions',
          key: 'actions',
        },
      ],
    }
  },
}
</script>
