<template>
  <b-modal
    :id="id"
    :title="editData.id? $t(`${editTitle}`) : $t(`${addTitle}`)"
    size="sm"
    ok-variant="success"
    cancel-variant="danger"
    :ok-title="$t('general.submit')"
    :cancel-title="$t('general.cancel')"
    centered
    no-close-on-backdrop
    @show="openModal"
    @ok.prevent="submit"
    @cancel="$bvModal.hide(`${id}`)"
  >
    <validation-observer ref="validation">
      <b-form-group
        :label="$t('general.title')"
        label-for="title_input_ru"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('general.title')"
        >
          <b-form-input
            id="title_input_ru"
            v-model="data.title"
            :placeholder="$t('general.title')"
            :state="errors.length > 0 ? false : null"
            class="mb-1 "
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import toast from '@/mixins/toast'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toast],
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      default: 'AddEditFormTypeModal',
      required: false,
    },
    addTitle: {
      type: String,
      default: 'general.add',
      required: false,
    },
    editTitle: {
      type: String,
      default: 'general.save',
      required: false,
    },
    okTitle: {
      type: String,
      default: 'general.save',
      required: false,
    },
    cancelTitle: {
      type: String,
      default: 'general.cancel',
      required: false,
    },
    editData: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      data: {
        title: '',
      },
      required,
    }
  },
  computed: {
    ...mapGetters('knowledgeBase', ['GET_SUBJECTS_LIST']),
  },
  methods: {
    ...mapActions('courses', ['CREATE_FORM_TYPE', 'UPDATE_FORM_TYPE']),
    openModal() {
      if (this.isEdit) {
        this.data = this.$_removeObjectConnection(this.editData)
      } else {
        this.data = {
          title: '',
        }
      }
    },
    submit() {
      this.$refs.validation.validate().then(success => {
        if (success) {
          if (this.isEdit) {
            this.UPDATE_FORM_TYPE(this.data).then(() => {
              this.$bvModal.hide('AddEditFormTypeModal')
              this.$_successToast(this.$t('general.edited'))
              this.$emit('success')
            })
          } else {
            this.CREATE_FORM_TYPE(this.data).then(() => {
              this.$bvModal.hide('AddEditFormTypeModal')
              this.$emit('success')
              this.$_successToast(this.$t('general.added'))
            })
          }
        } else {
          this.$_errorToast(this.$t('general.no_valid'))
        }
      })
    },
  },
}
</script>

<style>

</style>
